/* eslint-disable @typescript-eslint/naming-convention */
import type { RouteConfig } from '../types/route-config';

export const routes = {
  '/': {
    path: '/',
  },
  '/debug/connexion': {
    path: '/debug/connexion',
    queryParams: ['comingFrom'],
  },
  '/debug/inscription': {
    path: '/debug/inscription',
    queryParams: ['comingFrom'],
  },
  '/profil': {
    path: '/profil',
  },
  '/profil/:user/accueil': {
    path: '/profil/:user/accueil',
    params: ['user'],
  },
  '/profil/:user': {
    path: '/profil/:user',
    params: ['user'],
  },
  '/profil/:user/:toolId': {
    path: '/profil/:user/:toolId',
    params: ['user', 'toolId'],
  },
  '/profil/:user/competences-quark': {
    path: '/profil/:user/competences-quark',
    params: ['user'],
  },
  '/profil/:user/competences-quark/experience/:id': {
    path: '/profil/:user/competences-quark/experience/:id',
    params: ['user', 'id'],
  },
  '/profil/:user/competences-quark/formation/:id': {
    path: '/profil/:user/competences-quark/formation/:id',
    params: ['user', 'id'],
  },
  '/profil/:user/competences-quark/competence/:id': {
    path: '/profil/:user/competences-quark/competence/:id',
    params: ['user', 'id'],
  },
  '/profil/:user/comportement-aura/naturel': {
    path: '/profil/:user/comportement-aura/naturel',
    params: ['user'],
  },
  '/profil/:user/comportement-aura': {
    path: '/profil/:user/comportement-aura',
    params: ['user'],
  },
  '/profil/:user/invitations': {
    path: '/profil/:user/invitations',
    params: ['user'],
  },
  '/profil/:user/motivations-helio': {
    path: '/profil/:user/motivations-helio',
    params: ['user'],
  },
  '/profil/:user/parametres': {
    path: '/profil/:user/parametres',
    params: ['user'],
  },
  '/profil/:user/partenariat': {
    path: '/profil/:user/partenariat',
    params: ['user'],
  },
  '/profil/:user/potentiel': {
    path: '/profil/:user/potentiel',
    params: ['user'],
  },
  '/profil/:user/potentiel/competences': {
    path: '/profil/:user/potentiel/competences',
    params: ['user'],
  },
  '/profil/:user/potentiel/competences/:id': {
    path: '/profil/:user/potentiel/competences/:id',
    params: ['user', 'id'],
  },
  '/profil/:user/potentiel/metiers': {
    path: '/profil/:user/potentiel/metiers',
    params: ['user'],
  },
  '/profil/:user/potentiel/metiers/:id': {
    path: '/profil/:user/potentiel/metiers/:id',
    params: ['user', 'id'],
  },
  '/profil/:user/historique': {
    path: '/profil/:user/historique',
    params: ['user'],
  },
} as const;

export const config: RouteConfig<typeof routes> = {
  defaultLocale: 'fr',

  routes,

  translates: {
    fr: {
      '/': '/',
      '/debug/connexion': '/debug/connexion',
      '/debug/inscription': '/debug/inscription',
      '/profil': '/profil',
      '/profil/:user': '/profil/:user',
      '/profil/:user/:toolId': '/profil/:user/:toolId',
      '/profil/:user/accueil': '/profil/:user/accueil',
      '/profil/:user/competences-quark': '/profil/:user/competences-quark',
      '/profil/:user/competences-quark/experience/:id':
        '/profil/:user/competences-quark/experience/:id',
      '/profil/:user/competences-quark/formation/:id':
        '/profil/:user/competences-quark/formation/:id',
      '/profil/:user/competences-quark/competence/:id':
        '/profil/:user/competences-quark/competence/:id',
      '/profil/:user/comportement-aura/naturel':
        '/profil/:user/comportement-aura/naturel',
      '/profil/:user/comportement-aura': '/profil/:user/comportement-aura',
      '/profil/:user/invitations': '/profil/:user/invitations',
      '/profil/:user/motivations-helio': '/profil/:user/motivations-helio',
      '/profil/:user/parametres': '/profil/:user/parametres',
      '/profil/:user/partenariat': '/profil/:user/partenariat',
      '/profil/:user/potentiel': '/profil/:user/potentiel',
      '/profil/:user/potentiel/competences':
        '/profil/:user/potentiel/competences',
      '/profil/:user/potentiel/competences/:id':
        '/profil/:user/potentiel/competences/:id',
      '/profil/:user/potentiel/metiers': '/profil/:user/potentiel/metiers',
      '/profil/:user/potentiel/metiers/:id':
        '/profil/:user/potentiel/metiers/:id',
      '/profil/:user/historique': '/profil/:user/historique',
    },
    en: {
      '/': '/',
      '/debug/connexion': '/debug/sign-in',
      '/debug/inscription': '/debug/sign-up',
      '/profil': '/profile',
      '/profil/:user': '/profile/:user',
      '/profil/:user/:toolId': '/profile/:user/:toolId',
      '/profil/:user/accueil': '/profile/:user/home',
      '/profil/:user/competences-quark': '/profile/:user/quark-skills',
      '/profil/:user/competences-quark/experience/:id':
        '/profile/:user/quark-skills/experience/:id',
      '/profil/:user/competences-quark/formation/:id':
        '/profile/:user/quark-skills/formation/:id',
      '/profil/:user/competences-quark/competence/:id':
        '/profile/:user/quark-skills/skill/:id',
      '/profil/:user/comportement-aura/naturel':
        '/profile/:user/aura-behaviour/natural',
      '/profil/:user/comportement-aura': '/profile/:user/aura-behaviour',
      '/profil/:user/invitations': '/profile/:user/invitations',
      '/profil/:user/motivations-helio': '/profile/:user/helio-motivations',
      '/profil/:user/parametres': '/profile/:user/settings',
      '/profil/:user/partenariat': '/profile/:user/partnership',
      '/profil/:user/potentiel': '/profile/:user/potential',
      '/profil/:user/potentiel/competences': '/profile/:user/potential/skills',
      '/profil/:user/potentiel/competences/:id':
        '/profile/:user/potential/skills/:id',
      '/profil/:user/potentiel/metiers': '/profile/:user/potential/jobs',
      '/profil/:user/potentiel/metiers/:id':
        '/profile/:user/potential/jobs/:id',
      '/profil/:user/historique': '/profile/:user/history',
    },
  },
};
