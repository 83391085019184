/* eslint-disable @typescript-eslint/naming-convention */
import type { RouteConfig } from '../types/route-config';

export const routes = {
  '/': {
    path: '/',
  },
  '/connexion': {
    path: '/connexion',
    queryParams: ['comingFrom'],
  },
  '/inscription': {
    path: '/inscription',
    queryParams: ['comingFrom'],
  },
  '/mot-de-passe-oublie': {
    path: '/mot-de-passe-oublie',
    queryParams: ['confirmation_url'],
  },

  '/pole-emploi-connect': {
    path: '/pole-emploi-connect',
  },

  '/parcours': {
    path: '/parcours',
  },

  '/partenaires/:id/autorisation': {
    path: '/partenaires/:id/autorisation',
    params: ['id'],
  },

  '/invitations/:token': {
    path: '/invitations/:token',
    params: ['token'],
  },
  '/invitations/:token/refus': {
    path: '/invitations/:token/refus',
    params: ['token'],
  },
  '/invitations/:token/acceptation': {
    path: '/invitations/:token/acceptation',
    params: ['token'],
  },

  '/parcours/applications': {
    path: '/parcours/applications',
  },
  '/parcours/applications/:application': {
    path: '/parcours/applications/:application',
    params: ['application'],
  },

  '/parcours/etapes': {
    path: '/parcours/etapes',
  },
  '/parcours/etapes/:step': {
    path: '/parcours/etapes/:step',
    params: ['step'],
  },

  // '/parcours/professionnel': {
  //   path: '/parcours/professionnel',
  // },

  // '/parcours/talent': {
  //   path: '/parcours/talent',
  // },

  '/finalisation/aura': {
    path: '/finalisation/aura',
  },
  '/finalisation/helio': {
    path: '/finalisation/helio',
  },
  '/finalisation/quark': {
    path: '/finalisation/quark',
  },
  '/renouvellement-consentement': {
    path: '/renouvellement-consentement',
  },
  '/invitation-en-attente': {
    path: '/invitation-en-attente',
  },
  '/page-existante': {
    path: '/page-existante',
  },
} as const;

export const config: RouteConfig<typeof routes> = {
  defaultLocale: 'fr',

  routes,

  translates: {
    fr: {
      '/': '/',
      '/partenaires/:id/autorisation': '/partenaires/:id/autorisation',
      '/pole-emploi-connect': '/pole-emploi-connect',
      '/connexion': '/connexion',
      '/inscription': '/inscription',
      '/mot-de-passe-oublie': '/mot-de-passe-oublie',
      '/parcours': '/parcours',
      '/invitations/:token': '/invitations/:token',
      '/invitations/:token/acceptation': '/invitations/:token/acceptation',
      '/invitations/:token/refus': '/invitations/:token/refus',
      '/parcours/applications': '/parcours/applications',
      '/parcours/applications/:application':
        '/parcours/applications/:application',
      '/parcours/etapes': '/parcours/etapes',
      '/parcours/etapes/:step': '/parcours/etapes/:step',
      // '/parcours/professionnel': '/parcours/professionnel',
      // '/parcours/talent': '/parcours/talent',
      '/finalisation/aura': '/finalisation/aura',
      '/finalisation/helio': '/finalisation/helio',
      '/finalisation/quark': '/finalisation/quark',
      '/renouvellement-consentement': '/renouvellement-consentement',
      '/invitation-en-attente': '/invitation-en-attente',
      '/page-existante': '/page-existante',
    },
    en: {
      '/': '/',
      '/partenaires/:id/autorisation': '/partners/:id/authorization',
      '/pole-emploi-connect': '/pole-emploi-connect',
      '/connexion': '/sign-in',
      '/inscription': '/sign-up',
      '/mot-de-passe-oublie': '/forgotten-password',
      '/parcours': '/path',
      '/invitations/:token': '/invitations/:token',
      '/invitations/:token/acceptation': '/invitations/:token/accept',
      '/invitations/:token/refus': '/invitations/:token/refuse',
      '/parcours/applications': '/path/applications',
      '/parcours/applications/:application': '/path/applications/:application',
      '/parcours/etapes': '/path/steps',
      '/parcours/etapes/:step': '/path/steps/:step',
      // '/parcours/professionnel': '/path/professional',
      // '/parcours/talent': '/path/talent',
      '/finalisation/aura': '/finalization/aura',
      '/finalisation/helio': '/finalization/helio',
      '/finalisation/quark': '/finalization/quark',
      '/renouvellement-consentement': '/gdpr-consent',
      '/invitation-en-attente': '/pending-invitations',
      '/page-existante': '/existing-page',
    },
  },
};
