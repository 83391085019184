/* eslint-disable @typescript-eslint/naming-convention */
import type { RouteConfig } from '../types/route-config';

export const routes = {
  '/': {
    path: '/',
  },
  '/debug/connexion': {
    path: '/debug/connexion',
    queryParams: ['comingFrom'],
  },
  '/debug/inscription': {
    path: '/debug/inscription',
    queryParams: ['comingFrom'],
  },
  '/page': {
    path: '/page',
  },
  '/page/liste': {
    path: '/page/liste',
  },
  '/page/creation': {
    path: '/page/creation',
  },
  '/page/existante': {
    path: '/page/existante',
  },
  '/page/:orgUrl': {
    path: '/page/:orgUrl',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/pdfs/:userUrl/cv': {
    path: '/page/:orgUrl/pdfs/:userUrl/cv',
    params: ['orgUrl', 'userUrl'],
  },
  '/page/:orgUrl/pdfs/:userUrl/rapport-talent': {
    path: '/page/:orgUrl/pdfs/:userUrl/rapport-talent',
    params: ['orgUrl', 'userUrl'],
  },
  '/page/:orgUrl/pdfs/:userUrl/rapport-expert': {
    path: '/page/:orgUrl/pdfs/:userUrl/rapport-expert',
    params: ['orgUrl', 'userUrl'],
  },
  '/page/:orgUrl/tarifs': {
    path: '/page/:orgUrl/tarifs',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/dashboard': {
    path: '/page/:orgUrl/dashboard',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/statistiques': {
    path: '/page/:orgUrl/statistiques',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/ressources': {
    path: '/page/:orgUrl/ressources',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/administration/gestion-acces': {
    path: '/page/:orgUrl/administration/gestion-acces',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/administration/gestion-acces/nouvelles': {
    path: '/page/:orgUrl/administration/gestion-acces/nouvelles',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/administration/parametres': {
    path: '/page/:orgUrl/administration/parametres',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/administration/personnalisation': {
    path: '/page/:orgUrl/administration/personnalisation',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/analyse/diagnostic': {
    path: '/page/:orgUrl/analyse/diagnostic',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/analyse/diagnostic/:projectUrl': {
    path: '/page/:orgUrl/analyse/diagnostic/:projectUrl',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/analyse/diagnostic/:projectUrl/aura': {
    path: '/page/:orgUrl/analyse/diagnostic/:projectUrl/aura',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/analyse/diagnostic/:projectUrl/helio': {
    path: '/page/:orgUrl/analyse/diagnostic/:projectUrl/helio',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/analyse/diagnostic/:projectUrl/profil-collectif': {
    path: '/page/:orgUrl/analyse/diagnostic/:projectUrl/profil-collectif',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/analyse/diagnostic/:projectUrl/quark': {
    path: '/page/:orgUrl/analyse/diagnostic/:projectUrl/quark',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/analyse/prismotheque': {
    path: '/page/:orgUrl/analyse/prismotheque',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/experience': {
    path: '/page/:orgUrl/experience',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/experience/configuration': {
    path: '/page/:orgUrl/experience/configuration',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion': {
    path: '/page/:orgUrl/gestion',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/archives': {
    path: '/page/:orgUrl/gestion/archives',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/invitations': {
    path: '/page/:orgUrl/gestion/invitations',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/invitations/nouvelles': {
    path: '/page/:orgUrl/gestion/invitations/nouvelles',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/projets': {
    path: '/page/:orgUrl/gestion/projets',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/projets/:projectUrl': {
    path: '/page/:orgUrl/gestion/projets/:projectUrl',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/gestion/projets/:projectUrl/criteres-analyse': {
    path: '/page/:orgUrl/gestion/projets/:projectUrl/criteres-analyse',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/gestion/projets/:projectUrl/gestion-acces': {
    path: '/page/:orgUrl/gestion/projets/:projectUrl/gestion-acces',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/gestion/projets/:projectUrl/informations': {
    path: '/page/:orgUrl/gestion/projets/:projectUrl/informations',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/gestion/projets/:projectUrl/invitations': {
    path: '/page/:orgUrl/gestion/projets/:projectUrl/invitations',
    params: ['orgUrl', 'projectUrl'],
  },
  '/page/:orgUrl/gestion/projets/creation': {
    path: '/page/:orgUrl/gestion/projets/creation',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/tags': {
    path: '/page/:orgUrl/gestion/tags',
    params: ['orgUrl'],
  },
  '/page/:orgUrl/gestion/tags/creation': {
    path: '/page/:orgUrl/gestion/tags/creation',
    params: ['orgUrl'],
  },
} as const;

export const config: RouteConfig<typeof routes> = {
  defaultLocale: 'fr',

  routes,

  translates: {
    fr: {
      '/': '/',
      '/debug/connexion': '/debug/connexion',
      '/debug/inscription': '/debug/inscription',
      '/page': '/page',
      '/page/liste': '/page/liste',
      '/page/:orgUrl': '/page/:orgUrl',
      '/page/:orgUrl/pdfs/:userUrl/cv': '/page/:orgUrl/pdfs/:userUrl/cv',
      '/page/:orgUrl/pdfs/:userUrl/rapport-talent':
        '/page/:orgUrl/pdfs/:userUrl/rapport-talent',
      '/page/:orgUrl/pdfs/:userUrl/rapport-expert':
        '/page/:orgUrl/pdfs/:userUrl/rapport-expert',
      '/page/:orgUrl/tarifs': '/page/:orgUrl/tarifs',
      '/page/:orgUrl/statistiques': '/page/:orgUrl/statistiques',
      '/page/:orgUrl/administration/gestion-acces':
        '/page/:orgUrl/administration/gestion-acces',
      '/page/:orgUrl/administration/gestion-acces/nouvelles':
        '/page/:orgUrl/administration/gestion-acces/nouvelles',
      '/page/:orgUrl/administration/parametres':
        '/page/:orgUrl/administration/parametres',
      '/page/:orgUrl/administration/personnalisation':
        '/page/:orgUrl/administration/personnalisation',
      '/page/:orgUrl/analyse/diagnostic': '/page/:orgUrl/analyse/diagnostic',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl':
        '/page/:orgUrl/analyse/diagnostic/:projectUrl',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/aura':
        '/page/:orgUrl/analyse/diagnostic/:projectUrl/aura',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/helio':
        '/page/:orgUrl/analyse/diagnostic/:projectUrl/helio',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/quark':
        '/page/:orgUrl/analyse/diagnostic/:projectUrl/quark',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/profil-collectif':
        '/page/:orgUrl/analyse/diagnostic/:projectUrl/profil-collectif',
      '/page/:orgUrl/analyse/prismotheque':
        '/page/:orgUrl/analyse/prismotheque',
      '/page/:orgUrl/dashboard': '/page/:orgUrl/dashboard',
      '/page/:orgUrl/experience': '/page/:orgUrl/experience',
      '/page/:orgUrl/experience/configuration':
        '/page/:orgUrl/experience/configuration',
      '/page/:orgUrl/gestion': '/page/:orgUrl/gestion',
      '/page/:orgUrl/gestion/archives': '/page/:orgUrl/gestion/archives',
      '/page/:orgUrl/gestion/invitations': '/page/:orgUrl/gestion/invitations',
      '/page/:orgUrl/gestion/invitations/nouvelles':
        '/page/:orgUrl/gestion/invitations/nouvelles',
      '/page/:orgUrl/gestion/projets': '/page/:orgUrl/gestion/projets',
      '/page/:orgUrl/gestion/projets/:projectUrl':
        '/page/:orgUrl/gestion/projets/:projectUrl',
      '/page/:orgUrl/gestion/projets/:projectUrl/criteres-analyse':
        '/page/:orgUrl/gestion/projets/:projectUrl/criteres-analyse',
      '/page/:orgUrl/gestion/projets/:projectUrl/gestion-acces':
        '/page/:orgUrl/gestion/projets/:projectUrl/gestion-acces',
      '/page/:orgUrl/gestion/projets/:projectUrl/informations':
        '/page/:orgUrl/gestion/projets/:projectUrl/informations',
      '/page/:orgUrl/gestion/projets/:projectUrl/invitations':
        '/page/:orgUrl/gestion/projets/:projectUrl/invitations',
      '/page/:orgUrl/gestion/projets/creation':
        '/page/:orgUrl/gestion/projets/creation',
      '/page/:orgUrl/gestion/tags': '/page/:orgUrl/gestion/tags',
      '/page/:orgUrl/gestion/tags/creation':
        '/page/:orgUrl/gestion/tags/creation',
      '/page/:orgUrl/ressources': '/page/:orgUrl/ressources',
      '/page/creation': '/page/creation',
      '/page/existante': '/page/existante',
    },
    en: {
      '/': '/',
      '/debug/connexion': '/debug/sign-in',
      '/debug/inscription': '/debug/sign-up',
      '/page': '/page',
      '/page/liste': '/page/list',
      '/page/:orgUrl': '/page/:orgUrl',
      '/page/:orgUrl/pdfs/:userUrl/cv': '/page/:orgUrl/pdfs/:userUrl/cv',
      '/page/:orgUrl/pdfs/:userUrl/rapport-talent':
        '/page/:orgUrl/pdfs/:userUrl/talent-report',
      '/page/:orgUrl/statistiques': '/page/:orgUrl/statistics',
      '/page/:orgUrl/pdfs/:userUrl/rapport-expert':
        '/page/:orgUrl/pdfs/:userUrl/expert-report',
      '/page/:orgUrl/tarifs': '/page/:orgUrl/pricing',
      '/page/:orgUrl/administration/gestion-acces':
        '/page/:orgUrl/administration/access-control',
      '/page/:orgUrl/administration/gestion-acces/nouvelles':
        '/page/:orgUrl/administration/access-control/news',
      '/page/:orgUrl/administration/parametres':
        '/page/:orgUrl/administration/settings',
      '/page/:orgUrl/administration/personnalisation':
        '/page/:orgUrl/administration/personalization',
      '/page/:orgUrl/analyse/diagnostic': '/page/:orgUrl/analysis/diagnostic',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl':
        '/page/:orgUrl/analysis/diagnostic/:projectUrl',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/aura':
        '/page/:orgUrl/analysis/diagnostic/:projectUrl/aura',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/helio':
        '/page/:orgUrl/analysis/diagnostic/:projectUrl/helio',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/quark':
        '/page/:orgUrl/analysis/diagnostic/:projectUrl/quark',
      '/page/:orgUrl/analyse/diagnostic/:projectUrl/profil-collectif':
        '/page/:orgUrl/analysis/diagnostic/:projectUrl/collective-profile',
      '/page/:orgUrl/analyse/prismotheque':
        '/page/:orgUrl/analysis/prismotheque',
      '/page/:orgUrl/dashboard': '/page/:orgUrl/dashboard',
      '/page/:orgUrl/experience': '/page/:orgUrl/experience',
      '/page/:orgUrl/experience/configuration':
        '/page/:orgUrl/experience/configuration',
      '/page/:orgUrl/gestion': '/page/:orgUrl/management',
      '/page/:orgUrl/gestion/archives': '/page/:orgUrl/management/archives',
      '/page/:orgUrl/gestion/invitations':
        '/page/:orgUrl/management/invitations',
      '/page/:orgUrl/gestion/invitations/nouvelles':
        '/page/:orgUrl/management/invitations/news',
      '/page/:orgUrl/gestion/projets': '/page/:orgUrl/management/projects',
      '/page/:orgUrl/gestion/projets/:projectUrl':
        '/page/:orgUrl/management/projects/:projectUrl',
      '/page/:orgUrl/gestion/projets/:projectUrl/criteres-analyse':
        '/page/:orgUrl/management/projects/:projectUrl/analysis-criteria',
      '/page/:orgUrl/gestion/projets/:projectUrl/gestion-acces':
        '/page/:orgUrl/management/projects/:projectUrl/access-control',
      '/page/:orgUrl/gestion/projets/:projectUrl/informations':
        '/page/:orgUrl/management/projects/:projectUrl/informations',
      '/page/:orgUrl/gestion/projets/:projectUrl/invitations':
        '/page/:orgUrl/management/projects/:projectUrl/invitations',
      '/page/:orgUrl/gestion/projets/creation':
        '/page/:orgUrl/management/projects/new',
      '/page/:orgUrl/gestion/tags': '/page/:orgUrl/management/tags',
      '/page/:orgUrl/gestion/tags/creation':
        '/page/:orgUrl/management/tags/new',
      '/page/:orgUrl/ressources': '/page/:orgUrl/resources',
      '/page/creation': '/page/new',
      '/page/existante': '/page/existing',
    },
  },
};
